@import-normalize;

* {
    font-family: Helvetica, Arial, sans-serif;
}

.Website {
    font-size: 16px;
}

.top-header {
    background-color: #333333;
}

.main-nav {
    align-items: center;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    height: 70px;

    &__logo {
        font-family: "Raleway", sans-serif;
        text-transform: lowercase;
        white-space: nowrap;

        strong {
            color: #52afcc;
            position: relative;
            left: -10px;
        }
    }

    &__list {
        display: flex;
        justify-content: space-evenly;
        list-style-type: none;
        padding: 0;
        margin: 0;

        &__item {}
    }
}
